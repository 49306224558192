
@mixin simpleSpace {
    // Define spacing values based on a 0.25rem increment scale
    $spacings: (
      0: 0,
      1: 0.25rem, // 1 equals 0.25rem
      2: 0.5rem,  // 2 equals 0.5rem
      3: 1rem, // 3 equals 0.75rem
      4: 1.5rem,    // 4 equals 1rem
      5: 2rem, // 5 equals 1.25rem
      6: 2.25rem,  // 6 equals 1.5rem
      7: 2.5rem, // 7 equals 1.75rem
      8: 2.75rem,    // 8 equals 2rem
      9: 3rem, // 9 equals 2.25rem
      10: 4rem, // 10 equals 2.5rem
    ) !default;

    
    $prefixes: (
      p  : padding,
      px : (padding-left, padding-right),
      py : (padding-top, padding-bottom),
      pt : padding-top,
      pr : padding-right,
      pb : padding-bottom,
      pl : padding-left,
      m  : margin,
      mx : (margin-left, margin-right),
      my : (margin-top, margin-bottom),
      mt : margin-top,
      mr : margin-right,
      mb : margin-bottom,
      ml : margin-left,
    ) !default;
  
    @each $spacing-key, $spacing-value in $spacings {
      @each $attr-short, $attr-list in $prefixes {
        // Default spacing classes for all screen sizes
        .#{$attr-short}-#{$spacing-key} {
          @if type-of($attr-list) == list {
            @each $attr in $attr-list {
              #{$attr}: $spacing-value !important;
            }
          } @else {
            #{$attr-list}: $spacing-value !important;
          }
        }

        // Desktop-only version (applies only to screens 1200px and above)
        @media (min-width: 1200px) {
          .desktop-#{$attr-short}-#{$spacing-key} {
            @if type-of($attr-list) == list {
              @each $attr in $attr-list {
                #{$attr}: $spacing-value !important;
              }
            } @else {
              #{$attr-list}: $spacing-value !important;
            }
          }
        }
        @media (max-width: 1199px) {
          .mobile-#{$attr-short}-#{$spacing-key} {
            @if type-of($attr-list) == list {
              @each $attr in $attr-list {
                #{$attr}: $spacing-value !important;
              }
            } @else {
              #{$attr-list}: $spacing-value !important;
            }
          }
        }
      }
    }
}

// Combined width and height utilities mixin
@mixin sizeUtilities {
  // Width values
  $width-values: (
    "fit-content": fit-content,
    "100": 100%,
    "75": 75%,
    "70": 70%,
    "60": 60%,
    "50": 50%,
    "40": 40%,
    "35": 35%,
    "30": 30%,
    "25": 25%,
    "15": 15%,
    "10": 10%,
    "unset": unset,
    "auto": auto,
    "fit": fit-content // Adding w-fit for completeness
  ) !default;
  
  // Height values
  $height-values: (
    "fit-content": fit-content,
    "100": 100%,
    "75": 75%,
    "70": 70%,
    "60": 60%,
    "50": 50%,
    "40": 40%,
    "35": 35%,
    "30": 30%,
    "25": 25%,
    "15": 15%,
    "10": 10%,
    "unset": unset,
    "auto": auto
  ) !default;

  @each $key, $value in $width-values {
    .w-#{$key} {
      width: $value !important;
    }
  }

  @media (max-width: 1199px) {
    @each $key, $value in $width-values {
      .mobile-w-#{$key} {
        width: $value !important;
      }
    }
  }

  // Generate height classes
  @each $key, $value in $height-values {
    .h-#{$key} {
      height: $value !important;
    }

    @media (max-width: 1199px) {
      .mobile-h-#{$key} {
        height: $value !important;
      }
    }
  }
}

    
// Display utilities mixin
@mixin displayUtilities {
    $display-values: (block, flex, inline, inline-block, inline-flex, grid, contents, table, none) !default;

    @each $value in $display-values {
    .d-#{$value} {
        display: $value !important;
    }
    }
}

// Position utilities mixin
@mixin positionUtilities {
    $position-values: (static, relative, absolute, fixed, sticky) !default;

    @each $value in $position-values {
    .position-#{$value} {
        position: $value !important;
    }
    }
}

@mixin justifyUtilities {
  // Mapping class names to justify-content values
  $justify-values: (
    "normal": normal,
    "center": center,
    "space-between": space-between,
    "space-evenly": space-evenly,
    "end": end,
    "start": start
  ) !default;

  // Mapping for justify-items
  $justify-items-values: (
    "items-flex-start": flex-start
  ) !default;

  // Generate justify-content classes
  @each $key, $value in $justify-values {
    .justify-#{$key} {
      justify-content: $value !important;
    }
  }

  // Generate justify-items classes
  @each $key, $value in $justify-items-values {
    .justify-#{$key} {
      justify-items: $value !important;
    }
  }
}

// Floats and text alignment
@mixin floatAndTextUtilities {
    .f-left { float: left !important; }
    .f-right { float: right !important; }
    .text-start { text-align: start !important; }
    .text-right { text-align: right !important; }
    .text-end { text-align: end !important; }
    .text-center { text-align: center !important; }
}

@mixin positionAbsoluteUtilities {
  .left-0 { left: 0 !important; }
  .right-0 { right: 0 !important; }
  .top-0 { top: 0 !important; }
  .bottom-0 { bottom: 0 !important; }
}

// Margin autos
$directions: ('t': 'top', 'b': 'bottom', 'l': 'left', 'r': 'right');

@each $key, $dir in $directions {
    .m#{$key}-auto {
      margin-#{$dir}: auto !important;
    }
}
@mixin fontSizeMixin {
  // Define font sizes
  $font-sizes: (
    10: 0.625rem,
    12: 0.75rem,
    14: 0.875rem,
    16: 1rem,
    18: 1.125rem,
    20: 1.25rem,
    24: 1.5rem,
    30: 1.875rem,
    36: 2.25rem,
    48: 3rem,
  ) !default;

  $breakpoints: (
    lg: 1600px,
    md: 1200px,
    sm: 800px,
    xs: 500px
  ) !default;

  @each $size-key, $size-value in $font-sizes {
    .fs-#{$size-key} {
      font-size: $size-value !important;
    }
  }

  @each $size-key, $size-value in $font-sizes {
    @media (max-width: 500px) {
      .fs-xs-#{$size-key} {
        font-size: $size-value !important;
      }
    }

    @media (min-width: 501px) and (max-width: 800px) {
      .fs-sm-#{$size-key} {
        font-size: $size-value !important;
      }
    }

    @media (min-width: 801px) and (max-width: 1200px) {
      .fs-md-#{$size-key} {
        font-size: $size-value !important;
      }
    }

    @media (min-width: 1201px) and (max-width: 1600px) {
      .fs-lg-#{$size-key} {
        font-size: $size-value !important;
      }
    }
  }
}





// Include mixins where needed
@include simpleSpace;
@include displayUtilities;
@include positionUtilities;
@include justifyUtilities;
@include floatAndTextUtilities;
@include positionAbsoluteUtilities;
@include sizeUtilities;
@include fontSizeMixin;