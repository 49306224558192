// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

@font-face {
    font-family: 'Poppins';
    src: url('../public//fonts/Poppins/Poppins-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../public//fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../public//fonts/Poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../public//fonts/Poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../public//fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }